import React from "react";
import { Layout } from "../../components/Layout";
import { HeaderBuffer } from "../../components/Layout/Header";
import kidScripture from "../../images/kid-scripture.jpeg";

const KidsPage = () => {
  return (
    <Layout>
      <main className="min-h-[80vh]">
        <div className="bg-cyan-950">
          <HeaderBuffer />
        </div>
        <div className="w-full px-8 py-16 md:px-16 md:py-16 bg-cyan-900">
          <h1 className="text-white text-2xl text-left mb-4">
            Children's Ministry
          </h1>
          <h2 className="text-white text-5xl font-serif text-left">
            Let the little children come to Jesus
          </h2>
        </div>
        <div className="p-8 md:p-16 grid grid-flow-row grid-cols-1 md:grid-cols-2 items-center gap-8 md:gap-16">
          <div>
            <div className="text-cyan-900 text-2xl font-light">
              Discipling the next generation.
            </div>

            <div className="text-gray-500 text-lg">
              Children's Sunday school meets on Sunday mornings at 9:30am. We also
              have a Junior Church during service for children ages kindergarten
              through 5th grade.
            </div>
          </div>
          <div>
            <img
              src={kidScripture}
              className="object-cover h-[36rem] w-full rounded-2xl shadow-xl"
            />
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default KidsPage;
